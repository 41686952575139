// app/javascript/controllers/recordings_list_controller.js
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["list", "loading"];

  connect() {
    // Store the bound function
    this.boundHandleRecordingCompleted =
      this.handleRecordingCompleted.bind(this);

    document.addEventListener(
      "audio-recorder:completed",
      this.boundHandleRecordingCompleted
    );
  }

  disconnect() {
    document.removeEventListener(
      "audio-recorder:completed",
      this.boundHandleRecordingCompleted
    );
  }

  handleRecordingCompleted(event) {
    this.loadingTarget.style.display = "block";
    this.fetchRecordings(event.detail.songId);
  }

  fetchRecordings(songId) {
    fetch(`/recordings?song_id=${songId}`, {
      headers: {
        Accept: "text/html",
        "X-Requested-With": "XMLHttpRequest",
      },
    })
      .then((response) => response.text())
      .then((html) => {
        this.loadingTarget.style.display = "none";
        this.listTarget.innerHTML = html;

        // Dispatch update event if needed
        const event = new CustomEvent("recordings:updated", {
          bubbles: true,
          detail: {},
        });
        this.element.dispatchEvent(event);
      })
      .catch((error) => {
        // console.error("Error fetching recordings:", error);
      });
  }
}
