// app/javascript/controllers/tag_select_controller.js
import { Controller } from "@hotwired/stimulus";
import TomSelect from "tom-select";

// Connects to data-controller="tag-select"
export default class extends Controller {
  static values = {
    options: Array,
  };

  connect() {
    // Create DOM-based render functions for CSP compliance
    const renderItem = (data, escape) => {
      const div = document.createElement("div");
      div.className = "float-left mr-2 badge badge-neutral";
      div.textContent = data.text;
      return div;
    };

    const renderOption = (data, escape) => {
      const div = document.createElement("div");
      div.className = "px-3 py-2 hover:bg-gray-100";
      div.textContent = data.text;
      return div;
    };

    // Set up base options array
    const options = this.hasOptionsValue
      ? this.optionsValue.map((option) => ({
          value: option,
          text: option,
        }))
      : [];
    const existingItems = Array.from(this.element.selectedOptions).map(
      (option) => option.value
    );

    this.tomSelect = new TomSelect(this.element, {
      create: true,
      createFilter: null,
      options: options,
      items: existingItems,
      delimiter: ",",
      render: {
        item: renderItem,
        option: renderOption,
      },

      // Avoid plugins that might use Function constructor
      plugins: {
        remove_button: {
          title: "Remove",
          className: "ml-1 text-indigo-400 hover:text-indigo-600",
        },
      },

      // Static classes instead of dynamic ones
      controlClass:
        "ts-control block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6",
      dropdownClass:
        "ts-dropdown mt-1 block w-full rounded-md border border-gray-300 bg-white shadow-lg",
      wrapperClass:
        "ts-wrapper block w-full rounded-md border-gray-300 focus-within:border-indigo-500 focus-within:ring-1 focus-within:ring-indigo-500",
      inputClass:
        "ts-input block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6",
      dropdownContentClass: "py-1",
      itemClass:
        "ts-item inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-indigo-100 text-indigo-800 mr-1 my-0.5",
      optionClass:
        "ts-option block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 cursor-pointer",

      onChange: (value) => {
        // Ensure hidden input has correct name after changes
        const hiddenInput = this.tomSelect.wrapper.querySelector(
          'input[type="hidden"]'
        );
        if (hiddenInput) {
          hiddenInput.setAttribute("name", originalName);
        }
        this.element.dispatchEvent(new Event("change", { bubbles: true }));
      },
      // Disable features that might use eval
      // score: () => 1,
      // load: null,
    });

    // Handle form reset
    this.element.closest("form")?.addEventListener("reset", () => {
      this.tomSelect.clear();
    });
  }

  disconnect() {
    if (this.tomSelect) {
      this.tomSelect.destroy();
    }
  }
}
